export default {
  hotels: 'отели',
  transfers: 'трансферы',
  excursions: 'экскурсии',
  'booking': 'бронирование',
  'Booking': 'Бронирование',
  'Agent Log in': 'Вход для агентов',
  WE_CONNECT_THE_WORLD: 'МЫ СОЕДИНЯЕМ ВЕСЬ МИР!',
  'Our B2B best hotel booking platform is a complete online hotel solution for span travel agents and corporate clients.': 'Наша лучшая B2B платформа для бронирования отелей - это полное онлайн-решение для турагентов и корпоративных клиентов.',
  'Hotels in the world': 'Отели по всему миру',
'Hotels in Russia': 'Отели в России',
'Destination countries': 'Страны назначения',
'Transfers routes': 'Маршруты трансферов',
'Excursions': 'Экскурсии',
'About us': 'О нас',
'Log in': 'Авторизация',
'Forgot the password?': 'Забыли пароль?',
'Register with Mybookoffice!': 'Зарегистрируйтесь в Mybookoffice!',
'Sign up and enjoy competitive rates and exclusive conditions on hotels, vacation rentals, transfers, and excursions.': 'Зарегистрируйтесь и наслаждайтесь выгодными тарифами и эксклюзивными условиями на отели, аренду жилья для отдыха, трансферы и экскурсии.',
'Top up by the amount:': 'Пополнить на сумму:',
'Replenish': 'Пополнить',
'It s important to know!': 'Важно знать!',
'c': 'c',
'to': 'до',
'Wishes:': 'Пожелания:',
'Enter your wishes...': 'Введите пожелания...',
'Placement data:': 'Данные о размещении:',
'User data:': 'Данные о посетителях:',
'Adult': 'Взрослый',
'Children': 'Ребенок',
'Age': 'Возраст ребенка',
'First name': 'Имя',
'Last name': 'Фамилия',
'Total:': 'Итого:',
'adults': 'взрослых',
'Adults:': 'Взрослых:',
'children s': 'детей',
'Children:': 'Детей:',
'nights': 'ночей',
'Nights:': 'Ночей:',
'rooms': 'комнат',
'Rooms:': 'Комнат:',
'Do you really want to delete the record?': 'Вы действительно хотите удалить запись?',
'No, continue booking': 'Нет, продолжить бронирование',
'Yes, delete the order': 'Да, удалите заказ',
'The record was successfully deleted': 'Запись была успешно удалена',
'Do you really want to cancel your reservation?': 'Вы действительно хотите отменить свое бронирование?',
'Cancel': 'Отмена',
'Invoice': 'Счет',
'Voucher': 'Ваучер',
'Cancellation Policy and cost:': 'Правила и стоимость отмены:',
'Non-refundable': 'Не подлежит возврату',
'after': 'после',
'No': 'Нет ',
'Yes': 'Да',
'Ok': 'Хорошо',
'Free Wi-Fi': 'Бесплатный Wi-Fi',
'Free parking': 'Бесплатная парковка',
'Fireplace': 'Камин',
'Small pets (up to 5 kg) are allowed': 'Допускаются маленькие домашние животные (до 5 кг)',
'Cafe': 'Кафе',
'Spa center': 'Спа-центр',
'WifiFull': 'Wi-Fi Полный',
'Laundry': 'Прачечная',
'Animals': 'Животные',
'Hair': 'Парикмахерская',
'Restaurant': 'Ресторан',
'Nearest address:': 'Ближайший адрес:',
'City/District/Name of the hotel': 'Город/Район/Название отеля',
'Enter a name': 'Введите название',
'From date:': 'От даты:',
'Before the date:': 'До даты:',
'Exit': 'Выход',
'More': 'Подробнее',
'photo': 'фото',
'wrote a review': 'написал(а) отзыв',
'Date of stay:': 'Дата пребывания:',
'Expires after:': 'Истекает через:',
'Balance': 'Баланс',
'Replenishment of the balance': 'Пополнение баланса',
'Personal': 'Личный',
'Credit': 'Кредитный',
'Add a payment method': 'Добавить способ оплаты',
'To pay the bill, use the card or contact by phone or E-mail.': 'Чтобы оплатить счет, воспользуйтесь картой или обратитесь по телефону или E-mail.',
'The transaction was successful! Funds have been successfully credited to the account': 'Транзакция прошла успешно! Средства были успешно зачислены на счет',
'The transaction failed! Try to repeat the operation a little later': 'Транзакция не удалась! Попробуйте повторить операцию чуть позже',
'Done': 'Готово',
'Confirmed': 'Подтвержденные',
'Cancelled': 'Отмененные',
'There are no confirmed bookings': 'Подтвержденные бронирования отсутствуют',
'There are no cancelled bookings': 'Подтвержденные бронирования отсутствуют',
'Basket': 'Корзина',
'To access the shopping cart, add at least one room': 'Чтобы получить доступ к корзине покупок, добавьте хотя бы один номер',
'Orders in the shopping cart:': 'Заказов в корзине:',
'Clean the basket': 'Очистите корзину',
'Do you really want to delete orders?': 'Вы действительно хотите удалять заказы?',
'The trash was successfully emptied': 'Корзина была успешно очищена',
'Booking completed successfully! You will now be moved to the booking page': 'Бронирование успешно завершено! Теперь вы перейдете на страницу бронирования',
'Please fill in the main fields of the form': 'Пожалуйста, заполните основные поля формы',
'The booking was made with an error! Try again later': 'Бронирование было произведено с ошибкой! Попробуйте еще раз позже',
'Age of children': 'Возраст детей',
'Popular amenities': 'Популярные удобства',
'Reviews': 'Отзывы',
'Unfortunately, there were no available hotels under the specified conditions. Change the search terms!': 'К сожалению, по заданным условиям не нашлось свободных отелей. Измените условия поиска!',
'Change the search': 'Изменить поиск',
'penalty': 'штраф',
'Enter the address': 'Введите адрес',
'Room': 'Комната',
'Type of placement': 'Тип размещения',
'Number of stars': 'Кол-во звезд',
'Additionally': 'Дополнительно',
'In order to ensure the correct functioning of the site, we use cookies. We use some of the cookies to analyze and improve the user experience. You can find more detailed information on the Privacy Policy and Cookie Policy pages. If you agree to improve the user experience, click "Allow"': 'В целях корректного функционирования сайта мы используем файлы cookies. Некоторые из cookies мы используем для анализа и улучшения пользовательского опыта. Более подробную информацию вы можете найти на страницах Политика конфиденциальности и Политика cookie. Если вы согласны на улучшение пользовательского опыта, нажмите «Разрешить»',
'Allow': 'Разрешить',
'Reset everything': 'Сбросить все',
'Show on map': 'Показать на карте',
'Additional filters': 'Дополнительные фильтры',
'Prices and Offers': 'Цены и предложения',
'Show more ...': 'Показать еще ...',
'Search': 'Поиск',
'Hotel': 'Отель',
'Apartotel': 'Апартотель',
'The Motel': 'Мотель',
'Villa': 'Вилла',
'Rural hotel': 'Сельский отель',
'Guest house': 'Гостевой дом',
'Hostel': 'Хостел',
'Historic hotel': 'Исторический отель',
'Apartments': 'Апартаменты',
'Camping': 'Кемпинг',
'Add to Cart': 'Добавить',
'Time left': 'Осталось времени',
'* - paid services': '* - платные услуги',
'There are no options with the specified price': 'Нет вариантов с указанной ценой',
'from': 'от'
};
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';

const httpLink = createHttpLink({
  uri: 'https://mybookoffice.com/api/graphql/',
  // uri: process.env.browserHttpEndpoint || 'http://192.168.0.173:4000/api/graphql/',
  credentials: 'same-origin',
});
const cache = new InMemoryCache();

export const apolloClient =  new ApolloClient({
  link: httpLink,
  cache,
});



